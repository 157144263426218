import React from 'react';
import Resume from './Resume';
import '../css/app.css'
import '../css/resume.css'
import '../css/resumeGrid.css'

// import Hero from "./images/hero-comp.jpg"

function App() {
  return (
   <Resume />
  )
}

export default App;
